import PropTypes from "prop-types"
import React from "react"

import Icon from "../images/icon.svg"

const Header = ({ siteTitle }) => (
  <header className="flex items-center justify-between flex-wrap p-6">
    <div className="flex items-center flex-shrink-0 mr-6">
      <Icon className="w-12 h-12" />
      <h1 className="text-xl font-semibold tracking-light">I'm Bored</h1>
    </div>
    <div>
      <a href="mailto:odd.interactive@gmail.com">
        Contact Us
      </a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
