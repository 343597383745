/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="max-w-6xl mx-auto p-4">
        <main>{children}</main>
        <footer className="mt-12 text-gray-400 text-sm text-center">
          © {new Date().getFullYear()} odd interactive. All Rights Reserved.
          <br />
          Google Play and the Google Play logo are trademarks of Google LLC.
          <br />
          <a
            href="https://docs.google.com/document/d/1eUxwkE-d3LvdAP_m-CmQQFmf1A6W3GHUdEkdIj2sBh8/edit#heading=h.hiqs371rk5qt"
            target="_blank"
            className="underline"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
